
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useRoute } from "vue-router";
import * as Yup from "yup";
import service from "@/service/devFactory";
export default defineComponent({
  name: "plugin-figma-reset-password",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const route = useRoute();
    const resetSuccess = ref(false);
    const loading = ref("of");

    const passwordRules = Yup.string().required();

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      password: Yup.string().required().label("Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required()
        .label("Confirm password"),
    });
    //Form submit function
    const onSubmitForgotPassword = async (values: any) => {
      //Activate loading indicator
      loading.value = "on";
      try {
        const { data } = await service.auth.authControllerResetPassword({
          token: route.params.token.toString(),
          newPassword: values.password,
        });
        if (data) resetSuccess.value = true;
      } catch (error) {
        console.log(error);
      }
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      passwordRules,
      resetSuccess,
      loading,
    };
  },
});
